<template>
    <div class="flex flex-col mx-4 h-screen">    
        <div class="flex-auto">
            <img class="md:mx-20 my-4 h-28 md:h-32" :src="require('../../assets/logo.png')" />
        </div>
        <div class="flex-auto md:mx-20">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12 md:col-span-8">
                    <p class="text-gray-800 font-normal text-xl md:text-2xl mb-8 md:mb-24">
                        Legal. Acho que você vai gostar do que vai ver.<br/>Para iniciar, <b>vamos fazer o seu cadastro!</b>
                    </p>
                    <label v-if="!confirmacao" class="font-semibold text-lg text-gray-800 pb-2 block">Digite seu e-mail de trabalho</label>
                    <label v-if="confirmacao" class="font-semibold text-lg text-gray-800 pb-2 block">Digite o código enviado em seu email</label>
                    <div v-if="!confirmacao" class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-6">
                            <input v-model="form.email" placeholder="nome@empresa.com.br" type="email" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 mb-3 text-base w-full" />
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <button @click="registrar" type="button" class="transition duration-200 bg-yellow-400 hover:bg-yellow-500 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white px-8 py-3 text-sm md:text-base shadow-sm hover:shadow-md font-semibold text-center inline-block">
                                <span class="inline-block mr-2">Continuar</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div v-if="confirmacao" class="grid grid-cols-12 gap-4">
                        <div class="col-span-12 md:col-span-6">
                            <input v-model="form.codigoConfirm" type="text" class="border-none bg-gray-200 focus:ring-gray-800 px-3 py-3 mb-3 text-base w-full" />
                            <label @click="reenviarEmail" class="font-normal text-base text-gray-800 hover:text-blue-500 block">Reenviar e-mail</label>
                            <label @click="fazerLogin" class="font-normal text-base text-gray-800 hover:text-blue-500 block mt-2">Fazer login com outra conta </label>
                        </div>
                        <div class="col-span-12 md:col-span-6">
                            <button @click="verificar" type="button" class="transition duration-200 bg-yellow-400 hover:bg-yellow-500 focus:bg-yellow-800 focus:shadow-sm focus:ring-4 focus:ring-gray-800 focus:ring-opacity-50 text-white px-8 py-3 text-sm md:text-base shadow-sm hover:shadow-md font-semibold text-center inline-block">
                                <span class="inline-block mr-2">Verificar Código</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-4 h-4 inline-block">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-4 justify-self-center">
                    <img class="h-44 md:h-auto" :src="require('../../assets/facillgpd-cadastro.png')" />
                </div>
            </div>
        </div>
        <div class="flex-auto items-end">
            <div class="float-left">
                <progress-bar class="md:ml-20" :options="options" :value="value"/> 
                <label class="md:ml-20 block text-base font-medium text-center">
                    Passo 2 de 4
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            consultoria: false, 
            empresa: false,
            confirmacao: false,
            form: {
                email: '',
                codigoConfirm: '',
            },
            value: 50,
            options: {
                text: {
                    color: '#FFFFFF',
                    shadowEnable: false,
                    shadowColor: '#000000',
                    fontSize: 14,
                    fontFamily: 'Helvetica',
                    dynamicPosition: false,
                    hideText: false
                },
                progress: {
                    color: '#FBBF24',
                    backgroundColor: '#CCCC',
                    inverted: false
                },
                layout: {
                    height: 30,
                    width: 140,
                    verticalTextAlign: 61,
                    horizontalTextAlign: 43,
                    zeroOffset: 0,
                    strokeWidth: 30,
                    progressPadding: 0,
                    type: 'line'
                }
            }
        }
    },
    methods: {
        async registrar() {
            if(!this.form.email) return this.$vToastify.error("Digite seu email");
            if(this.$route.query && this.$route.query.empresa) {
                const link = `https://api.whatsapp.com/send?phone=554192821401&text=Olá tenho interesse em testar a plataforma como empresa. Segue meu email ${this.form.email}`;
                window.open(link, '_blank');
                return 
            }

            if(this.$route.query && this.$route.query.consultoria) this.form.consultor = true;
            if(this.$route.query && this.$route.query.codigo) this.form.codigo = this.$route.query.codigo;

            const resp = await this.$http.post("/registrar", this.form);
            if (!resp.data.success) {
                this.$vToastify.error(resp.data.err);
            } else {
                this.confirmacao = true;
                await localStorage.setItem("confirmar-codigo", true);
                await localStorage.setItem("confirmar-email", this.form.email);
                this.$vToastify.success("Digite o código de confirmação enviado no seu email");
            }
        },
        fazerLogin() {
            localStorage.removeItem('token');
            localStorage.removeItem('empresa');
            localStorage.removeItem('user');
            this.$store.commit('logout');
            this.$store.commit('setEmpresa', null);
            this.$router.push({path: '/login'});
            return
        },
        async reenviarEmail () {
            if(!this.form.email) return this.$vToastify.error("Digite seu email");
            const resp = await this.$http.post("/reenviarEmailConfirmacao", this.form);
            if (!resp.data.success) {
                this.$vToastify.error(resp.data.err);
            } else {
                this.$vToastify.success("Digite o código de confirmação enviado no seu email");
            }
        },
        async verificar () {
            if(!this.form.codigoConfirm) return this.$vToastify.error("Digite o código");
            const resp = await this.$http.post("/verificar", this.form);
            if (!resp.data.success) {
                this.$vToastify.error(resp.data.err);
            } else {
                await localStorage.removeItem('confirmar-codigo');
                await localStorage.removeItem('confirmar-email');
                this.$router.push({path:`/cadastrar/${resp.data.data._id}`});
            }
        }
    },
    async beforeMount() {
        if(await localStorage.getItem('confirmar-codigo') && await localStorage.getItem('confirmar-email')) {
            const email = await localStorage.getItem("confirmar-email");
            this.confirmacao = true;
            this.form.email = email;
        }
    }
}
</script>